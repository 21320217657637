import * as moment from 'moment';
import api, { ApiService } from '../../common/api/api.service';

export class CommitmentsController {
    data: any[];
    meta: { pagination: { total_count: number } };
    loading: boolean;
    watcher: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private api: ApiService,
    ) {}
    $onInit(): void {
        this.load();
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    load(): ng.IPromise<void> {
        this.loading = true;
        return this.api
            .get({
                url: 'contacts',
                data: {
                    fields: {
                        contacts: 'name,late_at',
                    },
                    filter: {
                        account_list_id: this.api.account_list_id,
                        late_at: this.lateAt(),
                        pledge_received: true,
                        status: 'Partner - Financial',
                    },
                    per_page: 3,
                },
            })
            .then((data) => {
                this.loading = false;
                this.data = data;
                this.meta = data.meta;
            });
    }
    daysSince(isoDate: string): number {
        return moment.utc(moment()).diff(moment.utc(moment(isoDate)), 'days');
    }
    totalContacts(): number {
        return this.meta?.pagination?.total_count || 0;
    }
    lateAt(): string {
        return `${moment.utc(moment(0)).format('YYYY-MM-DD')}..${moment
            .utc(moment().subtract(30, 'days'))
            .format('YYYY-MM-DD')}`;
    }
}

const Commitments = {
    template: require('./commitments.html'),
    controller: CommitmentsController,
};

export default angular.module('mpdx.home.commitments', [api]).component('homeCommitments', Commitments).name;
