import { ApiService } from '../../common/api/api.service';
import users, { UsersService } from '../../common/users/users.service';

export class HomeWelcomeController {
    greeting: string;
    appName = 'MPDX';
    watcher: () => void;
    nonCruUser = false;
    showBanner = process.env.SHOW_BANNER === 'true';
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private users: UsersService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
    ) {}
    $onInit(): void {
        if (process.env.APP_NAME) {
            this.appName = process.env.APP_NAME;
        }
        this.updateGreeting();
        this.watcher = this.$rootScope.$on('userUpdated', () => this.updateGreeting());
        if (this.showBanner) {
            this.checkIfCruOrg();
        }
        this.$log.log('showBanner', this.showBanner);
    }
    $onDestroy(): void {
        this.watcher();
    }
    updateGreeting(): void {
        const today = new Date();
        const currentHour = today.getHours();
        const name = this.users?.current?.first_name;
        this.greeting = name
            ? this.gettextCatalog.getString('Good Evening, {{ name }}.', { name })
            : this.gettextCatalog.getString('Good Evening,');

        if (currentHour < 12) {
            this.greeting = name
                ? this.gettextCatalog.getString('Good Morning, {{ name }}.', { name })
                : this.gettextCatalog.getString('Good Morning,');
        } else if (currentHour < 18) {
            this.greeting = name
                ? this.gettextCatalog.getString('Good Afternoon, {{ name }}.', { name })
                : this.gettextCatalog.getString('Good Afternoon,');
        }
    }
    checkIfCruOrg(): void {
        this.api
            .get({
                url: 'user/',
            })
            .then((user) => {
                this.nonCruUser = user.non_cru_user;
            });
    }
}

const Welcome = {
    controller: HomeWelcomeController,
    template: require('./welcome.html'),
};

export default angular.module('mpdx.home.welcome', [users]).component('homeWelcome', Welcome).name;
